import { render, staticRenderFns } from "./CyInventoryResourceDetails.vue?vue&type=template&id=7003a6b3&scoped=true"
import script from "./CyInventoryResourceDetails.vue?vue&type=script&lang=js"
export * from "./CyInventoryResourceDetails.vue?vue&type=script&lang=js"
import style0 from "./CyInventoryResourceDetails.vue?vue&type=style&index=0&id=7003a6b3&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7003a6b3",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VCol,VHover,VIcon,VRow,VSkeletonLoader})
