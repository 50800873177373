var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',[_c('h2',{staticClass:"h4 mt-1"},[_vm._v(" "+_vm._s(_vm.$t('general'))+" ")]),_c('dl',{staticClass:"mt-5"},[_vm._l((_vm.displayedGeneralAttributes),function(attr,index){return [(_vm.loading || !_vm._.isEmpty(attr.value))?_c('dt',{key:((attr.key) + "-label"),ref:((attr.key) + "-label"),refInFor:true,class:[
            'h6 line-height-md',
            { 'mt-3': index } ]},[_vm._v(" "+_vm._s(attr.label)+" ")]):_vm._e(),(_vm.loading)?_c('v-skeleton-loader',{key:((attr.key) + "-loader"),ref:((attr.key) + "-loader"),refInFor:true,staticClass:"attribute-value-loader",attrs:{"loading":_vm.loading,"type":"text","width":"200"}}):(!_vm._.isEmpty(attr.value))?_c('dd',{key:((attr.key) + "-value"),ref:((attr.key) + "-value"),refInFor:true,class:{
            'text-truncate ellipsis': attr.key === 'link',
          }},[(attr.key === 'tags')?_c('CyTagList',{staticClass:"tag-list",attrs:{"tags":attr.value,"variant":"default","small":"","data-cy":"tag-list"}}):(attr.key === 'project_canonical')?_c('router-link',{staticClass:"cy-link",attrs:{"to":{
              name: 'project',
              params: { orgCanonical: _vm.orgCanonical, projectCanonical: attr.value },
            },"data-cy":"project-link"}},[_vm._v(" "+_vm._s(attr.value)+" ")]):(attr.key === 'environment_canonical')?_c('router-link',{staticClass:"cy-link",attrs:{"to":{
              name: 'projectOverview',
              params: { orgCanonical: _vm.orgCanonical, projectCanonical: _vm.resource.project_canonical },
            },"data-cy":"environment-link"}},[_vm._v(" "+_vm._s(attr.value)+" ")]):[_vm._v(" "+_vm._s(attr.value)+" ")]],2):_vm._e()]})],2)]),_c('section',{staticClass:"mt-8 mb-4"},[_c('header',{staticClass:"attributes-header"},[_c('v-row',{attrs:{"align":"baseline"}},[_c('v-col',[_c('h2',[_vm._v(_vm._s(_vm.$t('attributes')))])]),_c('v-col',{staticClass:"px-0"},[_c('CyDevButton',{staticClass:"mr-1",nativeOn:{"click":function($event){return _vm.toggleAllAttributesExpanded.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.isAllAttributesExpanded ? 'Collapse all' : 'Expand all')+" ")])],1),_c('v-col',{attrs:{"cols":"7"}},[_c('CySearchBox',{attrs:{"label":_vm.$t('filterAttributes'),"clearable":"","hide-details":""},model:{value:(_vm.attrSearchText),callback:function ($$v) {_vm.attrSearchText=$$v},expression:"attrSearchText"}},[_c('v-icon',{attrs:{"slot":"append"},slot:"append"},[_vm._v(" search ")])],1)],1)],1)],1),(_vm.loading)?_c('div',{staticClass:"mt-4"},_vm._l((10),function(index){return _c('v-skeleton-loader',{key:("attr-loader-" + index),ref:"attr-tree-loader",refInFor:true,staticClass:"attribute-tree-loader",attrs:{"loading":_vm.loading,"type":"text"}})}),1):(!_vm._.isEmpty(_vm.resource))?_c('tree',{key:_vm.resource.id,ref:"tree",staticClass:"cy-tree mt-4",attrs:{"data":_vm.treeData,"filter":_vm.attrSearchText,"options":{
        filter: {
          emptyText: '',
          matcher: _vm.attrSearchMatcher,
        },
        multiple: false,
        propertyNames: {
          text: 'text',
          children: 'children',
          type: 'type',
        },
      },"data-cy":"tree"},on:{"tree:filtered":_vm.onTreeFiltered},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var node = ref.node;
return [_c('div',{staticClass:"width-100"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var hover = ref.hover;
return [_c('div',{class:("cy-tree__text cy-tree__text--type-" + (_vm._.camelCase(node.data.type)))},[_c('div',[_c('span',{ref:"tree-node-key",staticClass:"cy-tree__key mr-2",domProps:{"innerHTML":_vm._s(_vm.highlight(node.text))}}),(_vm.shouldDisplayAttributeValue(node))?_c('span',{ref:"tree-node-value",staticClass:"cy-tree__value",domProps:{"innerHTML":_vm._s(_vm.highlight(node.data.value))}}):_vm._e(),(_vm.shouldDisplayAttributeType(node))?_c('span',{ref:"tree-node-type",staticClass:"cy-tree__type",domProps:{"textContent":_vm._s(_vm.getNodeType(node))}}):_vm._e()]),_c('div',{staticClass:"cy-tree__copy-btn"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}]},[_c('CyCopyButton',{ref:"tree-node-copy-btn",attrs:{"copy-value":_vm.getValueForCopy(node),"copy-hint":_vm.getValueForCopy(node),"small":"","left":""}})],1)])])]}}],null,true)})],1)]}}])}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }